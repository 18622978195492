* {
  box-sizing: border-box;
}

//Logo Setting
.logo-name {
  display: flex;
  flex-direction: column;
  color: $white;
  justify-content: flex-end;

  h3 {
    cursor: pointer;
    font-family: $title-font-family;
    margin-bottom: 0 !important;
    transition: 0.3s ease-in-out;

    &:hover {
      color: $dark-blue;
    }
  }

  p {
    margin-bottom: 0 !important;
  }

}

.logo-container {
  img {
    width: 90%;
    height: 100%;
  }
}

//nav bar
.nav-bar {
  background: $sky-blue;
  padding: 1rem;
  border-bottom: 1px solid $white;

  .menu-nav {
    justify-content: flex-end;
    align-items: end;
    @include breakpoint(medium down) {
      justify-content: flex-start;
      text-align: center;
      margin-top: 1.5rem;
    }

    .menu-link {
      text-decoration: none;
      color: $white;
      font-family: $body-font-family;
      font-weight: bold;
      font-size: 1.1rem;
      transition: 0.3s ease-in-out;

      &:hover {
        color: $dark-blue;
      }
    }
  }
}

.header-slider-box {
  width: 100%;
  height: 40rem;

  .orbit-container {
    height: 100% !important;

    .slider-bg {
      background: rgba(52, 52, 52, 0.45);
      height: 100%;
      width: 100%;
      z-index: 2;
      position: absolute;
    }
  }

  .slide-1 {
    background-image: url("../img/slide-1.jpg");
    background-size: cover;
    background-position: center;
  }

  .slide-2 {
    background-image: url("../img/slide-2.jpg");
    background-size: cover;
    background-position: center;
  }

  .slide-3 {
    background-image: url("../img/slide-3.jpg");
    background-size: cover;
    background-position: center;
  }

  .slide-4 {
    background-image: url("../img/slide-4.jpg");
    background-size: cover;
    background-position: center;
  }

  .orbit-slide {
    height: 100%;

    .docs-example-orbit-slide {
      padding-right: 3rem;
      padding-left: 3rem;
    }

    .docs-example-orbit-slide {
      position: absolute;
      top: 30%;
      left: 10%;

      h3 {
        z-index: 10;
        position: relative;
        color: #9ddee8;
        font-family: $head-font-family;
        @include breakpoint(small down) {
          font-size: 1.5rem;

        }

      }

      p {
        color: $white;
        font-weight: bold;
        z-index: 10;
        position: relative;
      }
    }
  }
}

//Orbit bullet
.orbit-bullets button {
  background-color: $sky-blue !important;
}

.orbit-bullets button.is-active {
  background-color: $dark-blue !important;
}

// Services
.services-container {
  box-sizing: border-box;
  margin-top: 7rem;
  margin-bottom: 6rem;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-left: 0 !important;
  margin-right: 0 !important;
  cursor: pointer;
  height: 20rem !important;
  transition: 0.5s ease-in-out;
  @include breakpoint(small down) {
    .services-item{
      margin-bottom: 8rem;
    }
  }


  .callout {
    transition: 0.5s ease-in-out;
    padding: 0 !important;
    border: 1px solid $sky-blue;
    color: $dark-blue;
    padding-bottom: 0.2rem !important;

    .services-img-box {
      transition: 0.55s ease-in-out;
      height: 14.3rem !important;

      img {
        transition: 0.5s ease-in-out;
        height: 100%;
        aspect-ratio: 3/1;
        object-fit: cover;
        object-position: center;
        @include breakpoint(medium down) {
          aspect-ratio: 4/1;
        }
      }
    }

    .services-discrption, .services-title {
      padding-left: 1rem;
      padding-right: 1rem;
      transition: 0.2s ease-in-out;
      text-align: center;
    }

    .services-discrption {
      display: none;
      margin-top: -1.5rem;
    }
    .inside-services-title{
      display: none;
      padding-top: 4rem;
      margin-top: 4rem;
      text-align: center;
    }
  }
  @include breakpoint(medium down) {
    height: 94rem !important;
  }
}


.callout:hover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid $sky-blue;
  color: $white;
  height: 18rem !important;

  .services-bg-transparency {
    background-color: rgba(42, 44, 51, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .services-title {
   display: none;
  }

  .services-img-box {
    position: absolute;
    width: 50%;
    transform: translateY(-5rem) translateX(50%);
    height: 50%;
    z-index: 2;

    img {
      border-radius: 25%;
      border: 2px solid $sky-blue;
      height: 10rem !important;
    }

  }
.inside-services-title{
  display: block;
  z-index: 2;

}
  .services-discrption {
    display: block;
    z-index: 2;
  }
}

.services-1:hover {
  background-image: url("../img/service-1.jpg");
}

.services-2:hover {
  background-image: url("../img/service-2.jpeg");
}

.services-3:hover {
  background-image: url("../img/service-3.jpg");
}
.services-4:hover {
  background-image: url("../img/service-4.jpg");
}

// Section title
.section-title {
  text-align: center;
  margin-top: 9rem;
  margin-bottom: 4rem;
  font-family: $title-font-family;
  color: $dark-blue;

  strong {
    border-bottom: 2.5px solid $sky-blue;
    border-bottom-left-radius: 16%;
    margin-right: 0.1rem;
  }

  .title-logo {
    background: $sky-blue;
    position: absolute;
    width: 2.4rem;
    margin-top: 2.4rem;
    border-radius: 25%;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    @include breakpoint(small down) {
      margin-top: 0.4rem;
    }
  }
}
//Our Work
.our-work{
  background: #90bbc1c4;
  .galarie-img-box{
    img{
      width: 100%;
      aspect-ratio: 4/2;
      object-fit: cover;
      object-position: bottom;
    }
  }
  .our-work-description{
    color: $dark-blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 4rem;
    padding-right: 4rem;
    @include breakpoint(small down) {
      margin-top: 2rem;
      padding-bottom: 2rem;
    }

p{
  text-align: center;
  font-weight: bold;
}
   h4{
     margin-bottom: 1rem;
     font-family: "Rampart One", cursive;
     font-weight: bolder;
   }
  }
}
//Galarie container
.galarie-container{
  margin-bottom: 5rem;
    .galarie-img-box{
      padding: 0.5rem;
      .galarie-img{
        width: 100%;
        height: 16rem;
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center;
      }
    }
  //filter buttons
  .button-group {
    justify-content: center; // center the button group
    font-size: 18px;
    .button{
      @include breakpoint(small down) {
        font-size: 0.55rem !important;
      }
    }
  }

  .filter-simple-button.hollow {
    &.is-active,
    &:focus,
    &:active {
      background: #90bbc1c4;
      color: $dark-blue;
      font-size: 19px;
      @include breakpoint(small down) {
        font-size: 0.55rem !important;
      }
    }
  }


  // optional styles to round the button group edges
  //to make first and last buttons rounded
  .button-group.round {
    :first-child {
      border-radius: 5000px 0 0 5000px;
      @include breakpoint(medium up) {

      }
    }

    :last-child {
      border-radius: 0 5000px 5000px 0;
      @include breakpoint(medium up) {
        border-radius: 0 5000px 5000px 0;
      }

    }
  }
}
//footer
.footer {
  max-height: 30rem;
  padding: 2rem 3rem 2rem 3rem;
  border-top: 2px solid $dark-blue;
  background: $sky-blue;
  @include breakpoint(medium down) {
    display: flex;
    flex-direction: column;
    .footer-logo{
      order: 2;
    }
  }
.rights{
color: white;
  padding-left: 4%;
}
//footer logo
  .footer-logo {

    .logo-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-content: center;

      img {
        width: 100%;
        height: 7rem;
      }
    }
  }
  .logo-name {
    justify-content: center;

    h3 {
      margin-top: 2rem;
    }

    h3, p {
      margin-left: 0.5rem;
    }
  }
  //footer contact
  .footer-contact{
    padding-left: 4rem;
    @media only screen and (max-width: 1315px) and (min-width: 915px){
      padding-left: 0;
    }
      .contact-list{
    list-style-type: none;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.3rem;
    .list-item{
      i{
        margin-right: 0.5rem;
      }
      .contact-text{
        color: white;
        &:hover{
          color: $dark-blue;
        }
      }
      .social-icon{
        font-size:2rem;
        background: white;
        margin-right: 1rem;
        text-align: center;
        display: inline-flex;
        padding: 0.5rem;
        border-radius: 1.3rem;
        box-shadow: 0px 7px 13px -4px $dark-blue;
        width: 3rem;
        transition: 0.4s ease-in-out;
        i{
          margin-right: 0;
          color: $dark-blue;
          width: 100%;
        }
      }
   }
    .contact-social {
      padding-left: 1.5rem;
      margin-top: 0.5rem;

      .social-icon {
        &:hover {
          background: $dark-blue;
          i{
            color: white;
          }

        }
      }
      .instagram{
        &:hover {
          background: #c54464;

        }
      }
      .telegram{
        &:hover {
          background: #2da0d6;

        }
      }

    }
  }
    @include breakpoint(medium down) {
      text-align: center;
      padding-left: 0;
      ul{
        margin-left: 0;
        padding-bottom: 2rem;
        .contact-social{
          order: 1;
          margin-bottom: 1rem;
          padding-left: 0.8rem !important;
        }
        .contact-phone{
          order: 2;
          margin-top: 1rem;
        }
        .contact-email{
          order: 3;
        }
      }

    }
  }
}
